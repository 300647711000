import React, { useState } from 'react'
import { ItemData, Theme, SiteData } from '../../../utils/models'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { gtagEventClick } from '../../../utils/gtag'
import { copy2Clipboard } from '../../../utils/util'

interface Props {
  theme: Theme
  currentModalItem: ItemData
  Modal: (props) => JSX.Element
  isOpen: boolean
  siteData: SiteData
}

const ListingModal: React.FC<Props> = ({ theme, Modal, currentModalItem, isOpen, siteData }) => {
  const { text, subtext, primary, secondary, altBackground } = theme

  if (!isOpen) {
    return <></>
  }

  const renderActionButton = () => {
    if (!!currentModalItem.actionUrl) {
      return (
        <OutboundLink
          href={currentModalItem.actionUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={`bg-${primary} hover:bg-${secondary} px-16 mt-16 mb-48 text-white font-bold py-3 px-4 shadow border-b-4 border-${secondary} hover:border-gray-800 rounded`}
          onClick={() => gtagEventClick('click_item_action', currentModalItem.actionUrl)}
        >
          {siteData.listingUrlButtonLabel}
        </OutboundLink>
      )
    }
    return <></>
  }

  const renderCopyButton = () => {
    const [isCopied, setCopied] = useState(false)

    // 选中优惠码
    const selectCode = (e) => {
      e.preventDefault()
      const document = window.document
      if (document.body.createTextRange) {
        //IE
        const range = document.body.createTextRange()
        range.moveToElementText(e.target)
        range.select()
      } else if (window.getSelection) {
        //others
        const selection = window.getSelection()
        if (selection.setBaseAndExtent) {
          selection.setBaseAndExtent(e.target, 0, e.target, 1)
        } else {
          const range = document.createRange()
          range.selectNodeContents(e.target)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    }

    // copy 优惠码
    const copy = () => {
      if (isCopied) {
        window.open(currentModalItem.actionUrl)
      } else if (copy2Clipboard(currentModalItem.code)) {
        setCopied(true)
      }
    }

    if (!!currentModalItem.code) {
      return (
        <div className="text-center text-xl">
          <div className={`border border-gray-400 inline py-4 px-4 font-bold cursor-pointer`} onClick={selectCode}>
            {currentModalItem.code}
          </div>
          <div
            className={`bg-${primary} hover:bg-${secondary} inline text-white font-bold py-4 px-4 border border-${primary} hover:border-${secondary} rounded-r cursor-pointer`}
            // onClick={() => gtagEventClick('click_item_action', currentModalItem.actionUrl)}
            onClick={copy}
          >
            {isCopied ? 'Copied' : siteData.listingUrlButtonLabel}
          </div>
        </div>
      )
    }
    return (
      <div className="text-center text-xl">
        <div
          className={`bg-${primary} hover:bg-${secondary} inline text-white font-bold py-4 px-4 border border-${primary} hover:border-${secondary} rounded cursor-pointer`}
          // onClick={() => gtagEventClick('click_item_action', currentModalItem.actionUrl)}
          onClick={copy}
          style={{ textTransform: 'capitalize' }}
        >
          Continue to {currentModalItem.brand}
        </div>
      </div>
    )
  }

  const renderImage = () => {
    if (!!currentModalItem.image) {
      return <img src={currentModalItem.image} className="border h-32 mx-auto rounded-lg" />
    }
    return <></>
  }

  const renderCopyText = () => {
    if (!!currentModalItem.code) {
      return (
        <div className="pb-6">
          Copy and paste this code at{' '}
          <OutboundLink
            href={currentModalItem.actionUrl}
            target="_blank"
            className="text-blue-700 cursor-pointer hover:text-blue-900"
            onClick={() => gtagEventClick('click_item_action', currentModalItem.actionUrl)}
          >
            {currentModalItem.brand}
          </OutboundLink>
        </div>
      )
    }
    return <div className="pb-6">Tip: No Coupon Code Required!</div>
  }

  return (
    <Modal>
      <div className={`shadow-xl ${text} min-h-64 p-4 b-8 w-screen md:max-w-screen-md`}>
        <div className={`${altBackground} rounded-lg text-center`}>
          <div className="p-8">
            {renderImage()}
            <h2 className="text-2xl mt-4 mb-6">{currentModalItem.title}</h2>
            <p className={`${subtext} -mt-1`}>{currentModalItem.subtitle}</p>
            {/* <p className="mb-8 mt-4">{currentModalItem.description}</p> */}
            {/* {renderActionButton()} */}
            {renderCopyText()}
            {renderCopyButton()}
          </div>
          <div className="pt-4 pb-8 px-8 border-t border-gray-400 text-left">
            <p className="">Details:</p>
            <p className="">{currentModalItem.description}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ListingModal
