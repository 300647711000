import { ListingCardSize, ItemData } from './models'
import { gtagEventClick } from './gtag'

export const getHeightBasedOnCardSize = (listingCardSize: ListingCardSize): number => {
  switch (listingCardSize) {
    case ListingCardSize.SMALL:
      return 16
    case ListingCardSize.MEDIUM:
      return 24
    case ListingCardSize.LARGE:
      return 64
    default:
      return 48
  }
}

export const handleItemClick = (e, item: ItemData, handleOpenModal) => {
  if (!!item.description) {
    gtagEventClick('open_item_modal', item.title)
    handleOpenModal(e, item)
  } else if (!!item.actionUrl && window !== undefined) {
    gtagEventClick('click_item_action', item.actionUrl)
    window.open(item.actionUrl, '_blank')
  }
}

/**
 * 将指定文本复制到粘贴板中。
 *
 * @param {string} text 要复制到粘贴板的文本。
 * @return {boolean}
 */
export const copy2Clipboard = (text) => {
  const el = document.createElement('textarea')

  el.value = text

  // Prevent keyboard from showing on mobile
  el.setAttribute('readonly', '')

  el.style.contain = 'strict'
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  el.style.fontSize = '12pt' // Prevent zooming on iOS

  const selection = getSelection()
  let originalRange = false
  if (selection.rangeCount > 0) {
    originalRange = selection.getRangeAt(0)
  }

  document.body.appendChild(el)
  el.select()

  // Explicit selection workaround for iOS
  el.selectionStart = 0
  el.selectionEnd = text.length

  let success = false
  try {
    success = document.execCommand('copy')
  } catch (err) {
    console.log(err)
  }

  document.body.removeChild(el)

  if (originalRange) {
    selection.removeAllRanges()
    selection.addRange(originalRange)
  }

  return success
}
